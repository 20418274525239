@tailwind base;
@tailwind components;
@tailwind utilities;



@Layer base {
  body {
    @apply overflow-hidden text-primary bg-[#F6F6F6];
  }
  .h1 {
    @apply text-[54px] lg:text-[108px] font-bold capitalize leading-[100%] tracking-[-0.05em] mb-2;
  }
  .section {
    @apply h-screen w-screen;
  }
  .btn {
    @apply py-[16px] px-[44px] h-[66px] flex items-center justify-center text-base  font-semibold bg-primary;
  }
}
